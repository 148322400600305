import styled from 'styled-components';

 const  AboutCon=styled.div`
 `
const About=()=>{
    return(<AboutCon>
            About
    </AboutCon>
    )
}
export default About