import styled from 'styled-components';

 const  ContactCon=styled.div`
 `
const Contact=()=>{
    return(

        <ContactCon>
            Contact
    </ContactCon>
        )
}
export default Contact