import styled from 'styled-components';

 const  TcCon=styled.div`
 `
const Tc=()=>{
    return(
        <TcCon>
            Tc
    </TcCon>

    )
    
}
export default Tc