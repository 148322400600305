import  back1 from '../../../../assets/backgrounds/back1.jpg'
import  back2 from '../../../../assets/backgrounds/back2.jpg'
import  back3 from '../../../../assets/backgrounds/back3.jpg'
import  back4 from '../../../../assets/backgrounds/back4.png'

const others=[
    "maximize your returns and reach your financial goals with us",
    "Join the ranks of savvy investors and experience success with our investment opportunities"
]
const carouselData=[
    {
        img:back1,
        id:1,
        text:"Invest in your future with us, relax and watch your wealth grow"
    },
    {img:back2,
        id:2,
        text:"Take control of your financial future with our expert guidiance"
    },
    {img:back3,
        id:3,
        text:"Secure your Financial legacy with our proven investment strategies"
    },
    {
        id:4,
        img:back4,
        text:others[0]

    },

]
export default carouselData